import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectGlobal } from 'emotion'
import styled from 'react-emotion'
import { Button, Icon, Select } from 'antd'

import Records from './records'
import Respawn from './respawn'
import SnD from './snd'

// eslint-disable-next-line no-unused-expressions
injectGlobal`
  ul.ant-select-dropdown-menu {
    margin-left: 0px;
  }

  .striped-leaderboard-row {
    background-color: rgb(250, 250, 250);
  }

  .ant-table-thead {
    font-size: 12px;
    white-space: nowrap;
  }

  .ant-table-row {
    font-size: 12px;
  }

  .ant-table td { white-space: nowrap; }

  @media screen and (min-width: 600px) {
    .ant-table-fixed-columns-in-body > div {
      opacity: 0.0;
    }
  }
`

const CategorySelectContainer = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  margin-bottom: 12px;

  @media screen and (min-width: 480px) {
    justify-content: flex-end;
  }
`

const CategorySelect = styled(Select)`
  width: 100%;
  margin-bottom: 12px;

  @media screen and (min-width: 420px) {
    width: 268px;
  }
`

class MobileTeams extends Component {
  state = {
    mode: 'Records',
    isKeyVisible: false,
  }

  onCancel = () => {
    const isKeyVisible = this.state
    this.setState({ isKeyVisible: !isKeyVisible })
  }

  render() {
    const { onCancel } = this

    const { mode, isKeyVisible } = this.state
    const { tableData } = this.props

    return (
      <div>
        <CategorySelectContainer>
          <Button
            type="primary"
            style={{ marginRight: 'auto' }}
            onClick={() => this.setState({ isKeyVisible: !isKeyVisible })}
          >
            <Icon type="question-circle" /> Key
          </Button>
          <div>
            <span style={{ fontWeight: 'bold', lineHeight: '30px', marginRight: '8px' }}>
              Categories:
            </span>
          </div>
          <CategorySelect defaultValue="Records" onChange={value => this.setState({ mode: value })}>
            <Select.Option value="Records">Records</Select.Option>
            <Select.Option value="Respawn">Respawn Stats</Select.Option>
            <Select.Option value="SnD">SnD Stats</Select.Option>
          </CategorySelect>
        </CategorySelectContainer>
        {(() => {
          switch (mode) {
            case 'Respawn':
              return <Respawn tableData={tableData} showKey={isKeyVisible} onCancel={onCancel} />
            case 'SnD':
              return <SnD tableData={tableData} showKey={isKeyVisible} onCancel={onCancel} />
            case 'Records':
            default:
              return <Records tableData={tableData} showKey={isKeyVisible} onCancel={onCancel} />
          }
        })()}
      </div>
    )
  }
}

MobileTeams.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default MobileTeams

import React from 'react'
import PropTypes from 'prop-types'
import { Table, Modal } from 'antd'
import numeral from 'numeral'

const columns = [
  {
    dataIndex: 'Team',
    key: 'Rank',
    fixed: 'left',
    render: (text, record, idx) => <span>{idx + 1}</span>,
  },
  {
    title: 'Team',
    dataIndex: 'Team',
    key: 'Team',
    fixed: 'left',
    render: text => (
      <div>
        <span style={{ fontWeight: 'bold' }}>{text}</span>
      </div>
    ),
  },
  {
    title: 'K/D',
    dataIndex: 'SnDKD',
    key: 'SnDKD',
    sorter: (a, b) => a.SnDKD - b.SnDKD,
    render: text => numeral(text).format('0.00'),
    defaultSortOrder: 'descend',
  },
  {
    title: 'Heal%',
    dataIndex: 'SnDHealPct',
    key: 'SnDHealPct',
    sorter: (a, b) => numeral(a.SnDHealPct).value() - numeral(b.SnDHealPct).value(),
    render: text => numeral(text).format('00.00%'),
  },
  {
    title: 'ADR',
    dataIndex: 'ADR',
    key: 'ADR',
    sorter: (a, b) => a.ADR - b.ADR,
    render: text => numeral(text).format('0'),
  },
  {
    title: 'DPK',
    dataIndex: 'SnDDPK',
    key: 'SnDDPK',
    sorter: (a, b) => a.SnDDPK - b.SnDDPK,
    render: text => numeral(text).format('0'),
  },
  {
    title: 'Maps',
    dataIndex: 'SnDMaps',
    key: 'SnDMaps',
    sorter: (a, b) => a.SnDMaps - b.SnDMaps,
  },
]

const striped = (record, idx) => (idx % 2 === 1 ? 'striped-leaderboard-row' : '')

const SnD = ({ showKey, onCancel, tableData }) => {
  return (
    <div>
      <Table
        dataSource={tableData}
        columns={columns}
        size="small"
        pagination={{ pageSize: 20, size: 'default' }}
        rowClassName={striped}
        scroll={{ x: true }}
        rowKey={row => `${row.Team}`}
      />
      <Modal visible={showKey} title="Key" onCancel={onCancel} footer={false}>
        <ul>
          <li>
            <strong>K/D</strong> - Kill/Death Ratio
          </li>
          <li>
            <strong>Heal%</strong> - % of Damage Healed
          </li>
          <li>
            <strong>ADR</strong> - Average Damage per Round
          </li>
          <li>
            <strong>DPK</strong> - Damage per Kill
          </li>
          <li>
            <strong>Maps</strong> - Search and Destroy Maps Played
          </li>
        </ul>
      </Modal>
    </div>
  )
}

SnD.propTypes = {
  showKey: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  tableData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default SnD

import React from 'react'
import PropTypes from 'prop-types'
import { Table, Modal } from 'antd'
import numeral from 'numeral'

const columns = [
  {
    dataIndex: 'Team',
    key: 'Rank',
    fixed: 'left',
    render: (text, record, idx) => <span>{idx + 1}</span>,
  },
  {
    title: 'Team',
    dataIndex: 'Team',
    key: 'Team',
    fixed: 'left',
    render: text => (
      <div>
        <span style={{ fontWeight: 'bold' }}>{text}</span>
      </div>
    ),
  },
  {
    title: 'K/D',
    dataIndex: 'RespawnKD',
    key: 'RespawnKD',
    sorter: (a, b) => a.RespawnKD - b.RespawnKD,
    render: text => numeral(text).format('0.00'),
    defaultSortOrder: 'descend',
  },
  {
    title: 'Heal%',
    dataIndex: 'RespawnHealPct',
    key: 'RespawnHealPct',
    sorter: (a, b) => numeral(a.RespawnHealPct).value() - numeral(b.RespawnHealPct).value(),
    render: text => numeral(text).format('00.00%'),
  },
  {
    title: 'DPM',
    dataIndex: 'RespawnDPM',
    key: 'RespawnDPM',
    sorter: (a, b) => a.RespawnDPM - b.RespawnDPM,
    render: text => numeral(text).format('0'),
  },
  {
    title: 'DPK',
    dataIndex: 'RespawnDPK',
    key: 'RespawnDPK',
    sorter: (a, b) => a.RespawnDPK - b.RespawnDPK,
    render: text => numeral(text).format('0'),
  },
  {
    title: 'Maps',
    dataIndex: 'RespawnMaps',
    key: 'RespawnMaps',
    sorter: (a, b) => a.RespawnMaps - b.RespawnMaps,
  },
]

const striped = (record, idx) => (idx % 2 === 1 ? 'striped-leaderboard-row' : '')

const Respawn = ({ showKey, onCancel, tableData }) => {
  return (
    <div>
      <Table
        dataSource={tableData}
        columns={columns}
        size="small"
        pagination={{ pageSize: 20, size: 'default' }}
        rowClassName={striped}
        scroll={{ x: true }}
        rowKey={row => `${row.Team}`}
      />
      <Modal visible={showKey} title="Key" onCancel={onCancel} footer={false}>
        <ul>
          <li>
            <strong>K/D</strong> - Kill/Death Ratio
          </li>
          <li>
            <strong>Heal%</strong> - % of Damage Healed
          </li>
          <li>
            <strong>DPM</strong> - Damage per Minute
          </li>
          <li>
            <strong>DPK</strong> - Damage per Kill
          </li>
          <li>
            <strong>Maps</strong> - Respawn Game Mode Maps Played
          </li>
        </ul>
      </Modal>
    </div>
  )
}

Respawn.propTypes = {
  showKey: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  tableData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default Respawn
